.material-icons {
  vertical-align: middle;
  margin-right: 5px;
}

.ace_editor .ace_marker-layer .ace_selection {
  background: rgba(255, 255, 255, 0.2) !important;
}


.ace-vibrant-ink .ace_marker-layer .ace_selected-word {
  outline-style: none !important;
}
